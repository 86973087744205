// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as IconFirst from "./iconFirst";

function transformIcons(prim) {
  IconFirst.transformIcons();
}

function PfIcon(Props) {
  var className = Props.className;
  React.useEffect((function () {
          IconFirst.transformIcons();
        }), [className]);
  return React.createElement("span", {
              key: className,
              className: "inline-flex"
            }, React.createElement("i", {
                  className: className
                }));
}

var make = PfIcon;

export {
  transformIcons ,
  make ,
}
/* react Not a pure module */
